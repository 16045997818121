import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'components/App/App';
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './index.css';

const helmetContext = {};
const { helmet } = helmetContext;

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
        <App>
          <Helmet>
            <title>Hello World</title>
            <link rel="canonical" href="https://www.tacobell.com/" />
          </Helmet>
        </App>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
