import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './Grid.module.scss';
import one from '../img/1ax.png';
import two from '../img/2av.png';
import three from '../img/3ag.png';
import four from '../img/4agn.jpg';
import five from '../img/5AGP.png';
import six from '../img/6agk.png';
import peregorodka from '../img/7peregorodki.jpg';
import eight from '../img/8PA.png';
import nine from '../img/9PЕ.png';
import ten from '../img/10PW.png';
import eleven from '../img/11PM.png';
import twelve from '../img/12P.png';
import thirteen from '../img/13М.jpg';
import fourteen from '../img/14U.jpg';
import fifteen from '../img/15N.png';
import sixteen from '../img/16NA.png';

function AutoLayoutExample() {
  return (
    <Container className={style.flex}>
      <NavLink to="/catalog/ax" className={style.item}>
        <img src={one} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          AX
        </button>
      </NavLink>
      <NavLink to="/catalog/av" className={style.item}>
        <img src={two} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          AV
        </button>
      </NavLink>
      <NavLink to="/catalog/ag" className={style.item}>
        <img src={three} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          AG
        </button>
      </NavLink>
      <NavLink to="/catalog/agn" className={style.item}>
        <img src={four} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          AGN
        </button>
      </NavLink>
      <NavLink to="/catalog/agp" className={style.item}>
        <img src={five} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          AGP
        </button>
      </NavLink>
      <NavLink to="/catalog/agk" className={style.item}>
        <img src={six} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          AGK
        </button>
      </NavLink>
      <NavLink to="/catalog/pa" className={style.item}>
        <img src={eight} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          PA
        </button>
      </NavLink>
      <NavLink to="/catalog/pe" className={style.item}>
        <img src={nine} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          PE
        </button>
      </NavLink>
      <NavLink to="/catalog/pw" className={style.item}>
        <img src={ten} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          PW
        </button>
      </NavLink>
      <NavLink to="/catalog/pm" className={style.item}>
        <img src={eleven} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          PM
        </button>
      </NavLink>
      <NavLink to="/catalog/p" className={style.item}>
        <img src={twelve} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          P
        </button>
      </NavLink>
      <NavLink to="/catalog/m" className={style.item}>
        <img src={thirteen} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          M
        </button>
      </NavLink>
      <NavLink to="/catalog/u" className={style.item}>
        <img src={fourteen} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          U
        </button>
      </NavLink>
      <NavLink to="/catalog/n" className={style.item}>
        <img src={fifteen} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          N
        </button>
      </NavLink>
      <NavLink to="/catalog/na" className={style.item}>
        <img src={sixteen} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          NA
        </button>
      </NavLink>
      <NavLink to="/peregorodki" className={style.item}>
        <img src={peregorodka} alt="" className={style.img} />
        <button type="button" className={style.btn}>
          Перегородки
        </button>
      </NavLink>
    </Container>
  );
}

export default AutoLayoutExample;
