import React from 'react';
import s from './Inside.module.scss';

import inside1 from './img/inside1.jpg';




const Inside = () => {
  return (
    <div className={s.morePenalContainer}>
      <h1>ПРИХОВАНА ІНСАЙД</h1>
      <h2>СИСТЕМА ВІДКРИВАННЯ ДВЕРЕЙ</h2>
      <div className={s.textBox}>
        <p className={s.textOP}>
          Система відкривання ПРИХОВАНА ІНСАЙД– двері-невидимка з реверсивним
          відчиненням. Невидимі двері з новими можливостями. До її появи
          найчастіше застосовувалася зовнішня схема відкривання полотна,
          необхідність відчиняти двері в протилежний бік створювала масу
          незручностей і найчастіше порушувала початкову естетичну задумку
          дизайну інтер'єру. З появою нового прихованого короба з реверсивним
          (внутрішнім) відкриттям це завдання успішно вирішується.
        </p>
        <p>
          Головна особливість даної конструкції полягає в тому, що колір та
          фактура стіни та дверного полотна виконуються в єдиному стилі, фактурі
          та кольоровому рішенні. Системи відкривання дверей ІНСАЙД
          виготовляються лише із прихованими петлями.
        </p>
        <p>
          Приховані міжкімнатні двері під фарбування – це оптимальний спосіб
          візуального збільшення простору. Дане рішення дозволить створити
          відчуття простору та неповторний стиль кімнати.
        </p>
      </div>

      <img src={inside1} alt="" />
     
      

      <h2>КОМПЛЕКТАЦІЯ</h2>
      <div className={s.textBox}>
        <p className={s.textOP}>1. Дверне полотно;</p>
        <p>2. Прихована коробка ІНСАЙД.</p>
      </div>
      
    </div>
  );
};

export default Inside;
