import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import main from '../img/main.jpg';
import one from '../img/penal.jpg';
import two from '../img/peregorodka.jpg';
import style from './Slider.module.scss';
import { NavLink } from 'react-router-dom';
import './Slider.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default class HeroSlider extends Component {
  render() {
    const settings = {
      infiniteLoop: true,
      autoPlay: true,
      showThumbs: false,
      showIndicators: false,
    };

    return (
      <div className={style.slider}>
        <Carousel {...settings}>
          <div className={style.slide}>
            <img src={main} alt="" className={style.img} />
            <NavLink className={style.slide__title} to="/catalog/pw">
              <h2 className={style.infoTitle}>
                МІНІМАЛІЗМ ЗАВЖДИ В СТИЛІ. <br /> ФАРБОВАНІ ДВЕРІ.
              </h2>

              <p className={style.btn}>
                ДЕТАЛЬНІШЕ <span className={style.btn__icon}>→</span>
              </p>
            </NavLink>
          </div>
          <div className={style.slide}>
            <img src={one} alt="" className={style.img} />
            <NavLink className={style.slide__title} to="/penal">
              <h2 className={style.infoTitle}>
                ПЕНАЛ <br /> РОЗСУВНА СИСТЕМА <br /> ПРИХОВАНОГО МОНТАЖУ
              </h2>
              <p className={style.btn}>
                ДЕТАЛЬНІШЕ <span className={style.btn__icon}>→</span>
              </p>
            </NavLink>
          </div>
          <div className={style.slide}>
            <img src={two} alt="" className={`${style.img}`} />
            <NavLink className={`${style.slide__title}`} to="/peregorodki">
              <h2 className={style.infoTitle}>
                КАСКАД <br /> РОЗСУВНІ <br /> ПЕРЕГОРОДКИ
              </h2>
              <p className={style.btn}>
                ДЕТАЛЬНІШЕ <span className={style.btn__icon}>→</span>
              </p>
            </NavLink>
          </div>
        </Carousel>
      </div>
    );
  }
}
