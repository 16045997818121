import AutoLayoutExample from 'components/Home/Grid/Grid';
import HeroSlider from 'components/Home/Slider/Slider';



const Home = () => {
  return (
    <div className='homepage'>
      <HeroSlider/>
      <AutoLayoutExample/>
    </div>
  );
};

export default Home;
