import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import logo from './img/logo.png';
import './Header.css';

const Header = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const handlerActive = path => {
    return location.pathname === path ? 'active' : '';
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  const settings = {
    fixed: 'top',
  };

  return (
    <>
      <div className="header__placeholder"></div>
      <Navbar
        {...settings}
        collapseOnSelect
        expand="lg"
        variant="light"
        className="header"
        expanded={expanded}
      >
        <Container className="header__container">
            <Link to="/">
              <img src={logo} alt="logo" className="logo" />
            </Link>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end header__content"
            >
              <Nav activeKey={location.pathname} className="nav">
                <Nav.Link
                  className={`nav__link ${handlerActive('/')}`}
                  as={Link}
                  to="/"
                  onClick={handleNavClick}
                  style={{
                    fontWeight: '600',
                    borderBottom: handlerActive('/')
                      ? '2px solid rgb(8, 7, 7)'
                      : '',
                  }}
                >
                  Головна
                </Nav.Link>
                <Nav.Link
                  className={`nav__link ${handlerActive('/catalog')}`}
                  as={Link}
                  to="/catalog"
                  onClick={handleNavClick}
                  style={{
                    fontWeight: '600',
                    borderBottom: location.pathname.includes('/catalog')
                      ? '2px solid rgb(8, 7, 7)'
                      : '',
                  }}
                >
                  Каталог
                </Nav.Link>
                <Nav.Link
                  className={`nav__link ${handlerActive('/system')}`}
                  as={Link}
                  to="/system"
                  onClick={handleNavClick}
                  style={{
                    fontWeight: '600',
                    borderBottom: location.pathname.includes('/system')
                      ? '2px solid rgb(8, 7, 7)'
                      : '',
                  }}
                >
                  Системи відкривання
                </Nav.Link>
                <Nav.Link
                  className={`nav__link ${handlerActive('/projects')}`}
                  as={Link}
                  to="/projects"
                  onClick={handleNavClick}
                  style={{
                    fontWeight: '600',
                    borderBottom: location.pathname.includes('/projects')
                      ? '2px solid rgb(8, 7, 7)'
                      : '',
                  }}
                >
                  Проекти
                </Nav.Link>
                <Nav.Link
                  className={`nav__link ${handlerActive('/contacts')}`}
                  as={Link}
                  to="/contacts"
                  onClick={handleNavClick}
                  style={{
                    fontWeight: '600',
                    borderBottom: location.pathname.includes('/contacts')
                      ? '2px solid rgb(8, 7, 7)'
                      : '',
                  }}
                >
                  Контакти
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
