import { Outlet, useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { Helmet } from 'react-helmet-async';

const Layout = () => {
  const location = useLocation();
  const url = 'https://laola.com.ua' + (location.pathname === '/' ? '' : location.pathname);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>ЛАОЛА | Двері, кухні та фурнітура</title>
        <meta
          name="description"
          content="Деревʼяні двері та меблі, фурнітура, кухні та багато іншого. Проектування та монтаж. Українське виробництво та імпорт європейських моделей"
        />
        <link rel="canonical" href={url} />
        <link rel="alternate" hreflang="uk" href={url} />
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
export default Layout;
